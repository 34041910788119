import { createSop } from "@/server/multimodal-sop-server";
import { getWorkflowSop } from "@/server/workflow-server";

export const namespaced = true;

export const state = {
  isSopPublished: false,
  sopStatus: "completed", // ‘pending’ | ‘processing’ | ‘completed’ | ‘failure’
  sop: [],
  sopId: "",
  selectedSopIds: [],
  isUserChangeLocalData: false,
};

export const getters = {
  getter_is_ai_processing_sop(state) {
    return ["processing", "pending"].includes(state.sopStatus);
  },
};

export const actions = {
  async generateSop({ commit }, { workflowId }) {
    try {
      const { ok, data, errorMessage } = await createSop({ workflowId });
      if (!ok) {
        console.error(errorMessage);
        return;
      }
      commit("MUTATE_SOP_STATUS", data.item.status);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchSopFromDb({ commit }, workflowId) {
    try {
      const { data, ok } = await getWorkflowSop(workflowId);
      if (ok && data.item) {
        commit("MUTATE_SOP", data.item.content);
        commit("MUTATE_SOP_ID", data.item.id);
        commit("MUTATE_SOP_STATUS", data.item.status);
        commit("MUTATE_IS_SOP_PUBLISHED", data.item.activate);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async updateItem({ state, commit }, { updatedItem, index }) {
    const sop = state.sop.splice(index, 1, updatedItem);
    commit("MUTATE_SOP", sop);
    commit("MUTATE_IS_USER_CHANGE_LOCAL_DATA", true);
  },
  setSopId({ commit }, id) {
    commit("MUTATE_SOP_ID", id);
  },
};

export const mutations = {
  MUTATE_SOP_STATUS(state, status) {
    state.sopStatus = status;
  },
  MUTATE_SOP(state, sop) {
    state.sop = sop;
  },
  MUTATE_SOP_ID(state, id) {
    state.sopId = id;
  },
  MUTATE_IS_USER_CHANGE_LOCAL_DATA(state, isChange) {
    state.isUserChangeLocalData = isChange;
  },
  MUTATE_IS_SOP_PUBLISHED(state, isPublished) {
    state.isSopPublished = isPublished;
  },
};
